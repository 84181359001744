.dsn{
  &-header{
    background-color: #4363AD;
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    &_title{
      color: $c-white;
      font-size: 2rem;
      text-align: center;
      padding: 1em;
    }
  }//&_header
  &-container{
    @include margin-auto;
    max-width: 600px;
    width: 100%;
    padding: 0 15px;
  }
  &-field{
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100vh;
    box-sizing: border-box;
    margin: 0 auto;
    overflow-x: hidden;/*横向きのスクロール禁止*/
    overflow-y: scroll;/*縦向きのスクロール許可*/
    -webkit-overflow-scrolling: touch;/*IE、Edgeでスクロールバーを非表示にする*/
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display:none;
    }
    .dsn-field_list{
      .dsn-field_item{
        &:not(:first-child){
          margin-top: 30px;
        }
        &.dsn-left{

        }
        .dsn-left{
          &_massage{
            &__img{
              width: 50px;
              padding-top: 5px;
              img{
                width: 50px;
                height: auto;
              }
              @media only screen and (min-width:$pcwidth) {
                padding-top: 0;
                width: 100px;
                img{
                  width: 100px;
                  height: auto;
                }
              }
            }
            display: flex;
            align-items: flex-start;
            &__inner{
              font-size: 1.4rem;
              margin-left: 20px;
              position: relative;
              background: #FFF;
              border: solid 1px $c-main;
              border-radius: 10px;
              box-sizing: border-box;
              padding: 10px 15px;
              &:before {
                content: "";
                position: absolute;
                left: -29px;
                top: 15px;
                border-top: 6px solid transparent;
                border-right: 15px solid #FFF;
                border-bottom: 6px solid transparent;
                border-left: 15px solid transparent;
                z-index: 2;
              }
              &:after {
                content: "";
                position: absolute;
                left: -31px;
                top: 15px;
                border-top: 6px solid transparent;
                border-right: 15px solid $c-main;
                border-bottom: 6px solid transparent;
                border-left: 15px solid transparent;
                z-index: 1;
              }
            }
          }//&_massage
          &_list{
            margin: 0 0 0 auto;
            width: 260px;
            ul{
              margin-top: 15px;
              border: 1px solid $c-border;
              background-color: $c-white;
              li{
                color: $c-main;
                padding: 5px 10px;
                text-align: left;
                &:not(:first-child){
                  border-top: 1px solid $c-border;
                }
                &:before{
                  content: ">";
                  display:inline-block;
                  margin-right: 8px;
                }
                a{
                  color: $c-main;
                  text-decoration: none;
                  transition: .3s;
                  &:hover{
                    opacity:.7;
                  }
                  &:focus{
                    font-weight: 600;
                  }
                }
              }
            }
          }//&_list
          &_button{
            margin-top: 15px;
            button{
              font-size: 1.2rem;
              width: 56px;
              text-align: center;
              padding: 3px;
              border-radius: 3px;
              transition: .3s;
              &:hover{
                opacity:.7;
              }
              &:focus {
                outline: none;
                box-shadow: 0 0 0 4px #cbd6ee;
              }
            }
            .dsn-button_yes{
              color: $c-white;
              background: $c-main;
              margin-right: 8px;
            }
            .dsn-button_no{
              padding: 3px;
              border: solid 1px #D6DADE;
              border-radius: 3px;
              background: linear-gradient(#FEFEFE, #EEF1F3);
            }
          }//&_button
        }//.dsn-left
        &.dsn-right{
          text-align: right;
        }
        .dsn-right{
          &_massage{
            font-size: 1.4rem;
            text-align: left;
            margin-right: 20px;
            position: relative;
            display: inline-block;
            background: #FDF6D8;
            border: solid 1px #FFD01A;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 10px 15px;
            &:before {
              content: "";
              position: absolute;
              right: -29px;
              top: 15px;
              border-top: 6px solid transparent;
              border-left: 15px solid #FDF6D8;
              border-bottom: 6px solid transparent;
              border-right: 15px solid transparent;
              z-index: 2;
            }
            &:after {
              content: "";
              position: absolute;
              right: -31px;
              top: 15px;
              border-top: 6px solid transparent;
              border-left: 15px solid #FFD01A;
              border-bottom: 6px solid transparent;
              border-right: 15px solid transparent;
              z-index: 1;
            }
          }//&_massage
        }//.dsn-right
      }//.dsn-field_item
    }//.dsn-field_list
  }//&-field
  &-input{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-top: 1px solid $c-border;
    background-color: $c-white;
    z-index: 100;
    &_inner{
      display: flex;
      max-width: 600px;
      width: 100%;
      padding: 15px;
      @include margin-auto;
      input::placeholder{
        color: #B4B4B4;
      }
    }
    &_comment{
      height: 30px;
      width: 74%;
      display: block;
      font-size: 1.4rem;
      border: 1px solid #D3D3D3;
      border-radius: 4px;
      padding-left: 10px;
      &:focus{
        border: 1.3px solid $c-main;
      }
    }//&_comment
    &_button{
      cursor: pointer;
      height: 30px;
      width: 23%;
      margin: auto ;
      font-size: 13px;
      background: $c-main;
      color: white;
      display: block;
      opacity: .8;
      // デフォルトのボーダーを消す
      border: none;
      box-sizing: border-box;
      border-radius: 5px;
      transition: .3s;
      &:hover{
        opacity: 1;
      }
      &:focus{
        opacity: 1;
      }
    }
  }//&-input
}//.dsn
