@charset "UTF-8";
/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%;
}

legend {
  color: #000;
}

#yui3-css-stamp.cssreset {
  display: none;
}

figure {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
  outline: none;
}

/* clearfix */
.cf {
  zoom: 1;
}

.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

main {
  display: block;
}

a:hover {
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

/* iPhone 対応 */
input {
  -webkit-appearance: none;
}

/*----------------*/
/* 　　共通設定　　　*/
/*----------------*/
html {
  font-size: 62.5%;
  font-family: "YoGo-Medium","游ゴシック体", "Yu Gothic", YuGothic, Arial,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Helvetica, sans-serif;
  color: #272A2E;
  height: 100%;
}

@media all and (-ms-high-contrast: none) {
  html {
    font-family: "メイリオ", Meiryo, Helvetica, sans-serif;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html:not(:target) {
    font-family: "メイリオ", Meiryo, Helvetica, sans-serif\9;
  }
}

body {
  font-size: 1.6rem;
  line-height: 1.8;
  opacity: 1;
  transition: .2s .1s;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding-bottom: 0;
  background-color: #F3F8FC;
}

/*# sourceMappingURL=main.css.map */
.dsn-header {
  background-color: #4363AD;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.dsn-header_title {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  padding: 1em;
}

.dsn-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  padding: 0 15px;
}

.dsn-field {
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  overflow-x: hidden;
  /*横向きのスクロール禁止*/
  overflow-y: scroll;
  /*縦向きのスクロール許可*/
  -webkit-overflow-scrolling: touch;
  /*IE、Edgeでスクロールバーを非表示にする*/
  -ms-overflow-style: none;
}

.dsn-field::-webkit-scrollbar {
  display: none;
}

.dsn-field .dsn-field_list .dsn-field_item:not(:first-child) {
  margin-top: 30px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage {
  display: flex;
  align-items: flex-start;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage__img {
  width: 50px;
  padding-top: 5px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage__img img {
  width: 50px;
  height: auto;
}

@media only screen and (min-width: 601px) {
  .dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage__img {
    padding-top: 0;
    width: 100px;
  }
  .dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage__img img {
    width: 100px;
    height: auto;
  }
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage__inner {
  font-size: 1.4rem;
  margin-left: 20px;
  position: relative;
  background: #FFF;
  border: solid 1px #1070D2;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 15px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage__inner:before {
  content: "";
  position: absolute;
  left: -29px;
  top: 15px;
  border-top: 6px solid transparent;
  border-right: 15px solid #FFF;
  border-bottom: 6px solid transparent;
  border-left: 15px solid transparent;
  z-index: 2;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_massage__inner:after {
  content: "";
  position: absolute;
  left: -31px;
  top: 15px;
  border-top: 6px solid transparent;
  border-right: 15px solid #1070D2;
  border-bottom: 6px solid transparent;
  border-left: 15px solid transparent;
  z-index: 1;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list {
  margin: 0 0 0 auto;
  width: 260px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list ul {
  margin-top: 15px;
  border: 1px solid #D6DADE;
  background-color: #fff;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list ul li {
  color: #1070D2;
  padding: 5px 10px;
  text-align: left;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list ul li:not(:first-child) {
  border-top: 1px solid #D6DADE;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list ul li:before {
  content: ">";
  display: inline-block;
  margin-right: 8px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list ul li a {
  color: #1070D2;
  text-decoration: none;
  transition: .3s;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list ul li a:hover {
  opacity: .7;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_list ul li a:focus {
  font-weight: 600;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_button {
  margin-top: 15px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_button button {
  font-size: 1.2rem;
  width: 56px;
  text-align: center;
  padding: 3px;
  border-radius: 3px;
  transition: .3s;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_button button:hover {
  opacity: .7;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_button button:focus {
  outline: none;
  box-shadow: 0 0 0 4px #cbd6ee;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_button .dsn-button_yes {
  color: #fff;
  background: #1070D2;
  margin-right: 8px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-left_button .dsn-button_no {
  padding: 3px;
  border: solid 1px #D6DADE;
  border-radius: 3px;
  background: linear-gradient(#FEFEFE, #EEF1F3);
}

.dsn-field .dsn-field_list .dsn-field_item.dsn-right {
  text-align: right;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-right_massage {
  font-size: 1.4rem;
  text-align: left;
  margin-right: 20px;
  position: relative;
  display: inline-block;
  background: #FDF6D8;
  border: solid 1px #FFD01A;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 15px;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-right_massage:before {
  content: "";
  position: absolute;
  right: -29px;
  top: 15px;
  border-top: 6px solid transparent;
  border-left: 15px solid #FDF6D8;
  border-bottom: 6px solid transparent;
  border-right: 15px solid transparent;
  z-index: 2;
}

.dsn-field .dsn-field_list .dsn-field_item .dsn-right_massage:after {
  content: "";
  position: absolute;
  right: -31px;
  top: 15px;
  border-top: 6px solid transparent;
  border-left: 15px solid #FFD01A;
  border-bottom: 6px solid transparent;
  border-right: 15px solid transparent;
  z-index: 1;
}

.dsn-input {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #D6DADE;
  background-color: #fff;
  z-index: 100;
}

.dsn-input_inner {
  display: flex;
  max-width: 600px;
  width: 100%;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

.dsn-input_inner input::placeholder {
  color: #B4B4B4;
}

.dsn-input_comment {
  height: 30px;
  width: 74%;
  display: block;
  font-size: 1.4rem;
  border: 1px solid #D3D3D3;
  border-radius: 4px;
  padding-left: 10px;
}

.dsn-input_comment:focus {
  border: 1.3px solid #1070D2;
}

.dsn-input_button {
  cursor: pointer;
  height: 30px;
  width: 23%;
  margin: auto;
  font-size: 13px;
  background: #1070D2;
  color: white;
  display: block;
  opacity: .8;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  transition: .3s;
}

.dsn-input_button:hover {
  opacity: 1;
}

.dsn-input_button:focus {
  opacity: 1;
}
