// フォント
$c-font : #272A2E;
// メイン
$c-main : #1070D2;
// 背景
$c-back : #F3F8FC;
// ボーダー
$c-border : #D6DADE;
// white
$c-white : #fff;

$spwidth: 600px;
$pcwidth: 601px;


@mixin margin-auto{
  margin-left: auto;
  margin-right: auto;
}

/*----------------*/
/* 　　共通設定　　　*/
/*----------------*/

html {
  font-size: 62.5%;
  font-family: "YoGo-Medium","游ゴシック体", "Yu Gothic", YuGothic, Arial,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Helvetica, sans-serif;
  color: $c-font;
  height: 100%;
  @media all and (-ms-high-contrast:none) {
    font-family:"メイリオ", Meiryo, Helvetica, sans-serif;
  }
  &:not(:target) {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-family:"メイリオ", Meiryo, Helvetica, sans-serif\9;
    }
  }
}

body {
  font-size: 1.6rem;
  line-height: 1.8;
  opacity: 1;
  transition: .2s .1s;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding-bottom: 0;
  background-color: $c-back;
}

/*# sourceMappingURL=main.css.map */
